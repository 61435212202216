import React, { Component, CSSProperties } from 'react';
import styles from './carditem.module.css';

type Props = {
    name: string;
    image?: string;
    dark?: boolean;
    id: number;
    onClick: Function;
    textbox: boolean;
    selected: boolean;
    lengthy: boolean;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
};

type State = {
    name: string;
    image: string;
    dark: boolean;
    id: number;
};

class CardItem extends Component<Props, State> {
    //in props check for install and if not return the install component instead of the login

    constructor(props: Props) {
        super(props);

        this.state = {
            name: this.props.name,
            image: '',
            dark: false,
            id: this.props.id,
        };
    }

    render() {
        const { lengthy } = this.props;
        const s: CSSProperties = {
            height: 'auto',
        };
        const t: CSSProperties = {};
        if (this.props.textbox === true) {
            return (
                <div
                    className={styles.textcontainer}
                    onClick={() => this.props.onClick(this.state.id)}
                    style={lengthy ? s : t}
                >
                    <div>{this.state.name}</div>
                    <form onSubmit={this.props.handleSubmit}>
                        <input
                            id="accbox"
                            inputMode="decimal"
                            type="number"
                            className={styles.cardText}
                            placeholder="0.00"
                            step=".01"
                            autoFocus
                            onChange={this.props.handleChange}
                        ></input>
                    </form>
                </div>
            );
        } else if (this.props.selected) {
            return (
                <div
                    className={styles.containerSelected}
                    onClick={() => this.props.onClick(this.state.id)}
                    style={lengthy ? s : t}
                >
                    {this.state.name}
                </div>
            );
        } else {
            return (
                <div
                    className={styles.container}
                    onClick={() => this.props.onClick(this.state.id)}
                    style={lengthy ? s : t}
                >
                    {this.state.name}
                </div>
            );
        }
    }
}

export default CardItem;
