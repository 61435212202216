import React, { Component } from 'react';
import styles from './container.module.css';
import axios from 'axios';

import Install from '../install/Install';
import Login from '../login/Login';
import LoggedInContainer from '../loggedincontainer/LoggedInContainer';

type State = {
    /*
     * version denotes current salestrackr version
     */
    version: string;
    /*
     * View controls what portion of the app to show,
     * 0: install PWA control
     * 1: login
     * 2: main app
     */
    view: number;
};

class Container extends Component<{}, State> {
    //in props check for install and if not return the install component instead of the login

    constructor(p: {}) {
        super(p);

        this.state = {
            version: '0.0.1',
            view: 0,
        };

        this.updateView = this.updateView.bind(this);
    }

    componentDidMount() {
        //check for installation
        if (window.matchMedia('(display-mode: standalone)').matches === true) {
            this.updateView(1);
            return axios
                .get('/api/user/loggedin')
                .then((response) => {
                    if (response.data.loggedin) {
                        this.updateView(2);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            this.updateView(0);
        }
        //check for login
    }

    updateView(id: number): void {
        this.setState({ view: id });
    }

    render() {
        switch (this.state.view) {
            case 0:
                return (
                    <div className={styles.mainContainer}>
                        <Install />
                    </div>
                );
            case 1:
                return (
                    <div className={styles.mainContainer}>
                        <Login updateView={this.updateView} />
                    </div>
                );
            case 2:
                return (
                    <div className={styles.mainContainer}>
                        <LoggedInContainer updateView={this.updateView} />
                    </div>
                );
            default:
                return <div className={styles.mainContainer}>Error</div>;
        }
    }
}

export default Container;
