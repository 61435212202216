import React, { Component } from 'react';
import styles from './admin.module.css';
import axios from 'axios';
import Loading from '../loading/Loading';
import Adduser from '../adduser/Adduser';
import { UserInfo, ButtonColor } from '../../types';
import User from '../user/User';
import Button from '../button/Button';

type Props = {};

type State = {
    adduser_open: boolean;
    active_data: storeData;
    active_user: UserInfo;
    loading: boolean;
    adding_existing_user: boolean;
    adding_new_user: boolean;
    data: Array<storeData>;
    deleteU: number; //default 0 no action, 1 prompts the confirmation modal, 2 deletes user
};

interface storeData {
    store: number;
    users: Array<UserInfo>;
}

//TODO update buttons to show correct words, remove from store option, find button for adding existing user followed by an add button

class Admin extends Component<Props, State> {
    myRef: any = React.createRef();

    constructor(props: Props) {
        super(props);

        this.state = {
            data: [],
            adduser_open: false,
            active_data: { store: 0, users: [] },
            active_user: {
                email: '',
                username: '',
                id: -1,
                name: 'New User',
                admin: false,
            },
            loading: true,
            adding_existing_user: false,
            adding_new_user: true,
            deleteU: 0,
        };

        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleStoreChange = this.handleStoreChange.bind(this);

        this.deleteUser = this.deleteUser.bind(this);
        this.removeUser = this.removeUser.bind(this);
        this.update = this.update.bind(this);
        this.updateButStay = this.updateButStay.bind(this);
        this.setLoading = this.setLoading.bind(this);
    }

    setLoading() {
        this.setState({ loading: true });
    }

    updateButStay() {
        //updates data but doesn't change active_user
        axios
            .get('/api/admin/getMyUsers') //admin things
            .then((response) => {
                let cstore = this.state.active_data.store;
                let nstore = response.data.userdata[0]; //assign just the first value for now
                for (let i = 0; i < response.data.userdata.length; i++) {
                    if (response.data.userdata[i].store === cstore) {
                        nstore = response.data.userdata[i];
                    }
                }
                this.setState({
                    data: response.data.userdata,
                    active_data: nstore,
                    loading: false,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    update() {
        axios
            .get('/api/admin/getMyUsers') //admin things
            .then((response) => {
                let cstore = this.state.active_data.store;
                let nstore = response.data.userdata[0]; //assign just the first value for now
                for (let i = 0; i < response.data.userdata.length; i++) {
                    if (response.data.userdata[i].store === cstore) {
                        nstore = response.data.userdata[i];
                    }
                }
                this.setState({
                    deleteU: 0, //set the delete modal back to default
                    loading: false, //got response no longer loading
                    data: response.data.userdata,
                    active_data: nstore,
                    active_user: {
                        //since that user was deleted, set the new viewable user
                        email: '',
                        username: '',
                        id: -1,
                        name: 'New User',
                        admin: false,
                    },
                    adding_new_user: true,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    removeUser() {
        this.setState({ loading: true });
        axios
            .post('/api/admin/delUserFromStore', {
                user: this.state.active_user,
                store: this.state.active_data.store,
            })
            .then((response) => {
                this.update();
            })
            .catch((error) => {});
    }

    componentDidMount() {
        let { offsetHeight } = this.myRef;
        axios
            .get('/api/admin/getMyUsers') //admin things
            .then((response) => {
                this.setState({
                    loading: false,
                    data: response.data.userdata,
                    active_data: response.data.userdata[0],
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    deleteUser() {
        this.setState({ deleteU: this.state.deleteU + 1 }, () => {
            if (this.state.deleteU === 2) {
                //send the user delete
                this.setState({ loading: true });
                axios
                    .post('/api/admin/deleteUser', {
                        user: this.state.active_user,
                    })
                    .then((response) => {
                        this.update();
                    })
                    .catch((error) => {});
            }
        });
    }

    handleStoreChange(event: React.ChangeEvent<HTMLSelectElement>) {
        for (let i = 0; i < this.state.data.length; i++) {
            if (this.state.data[i].store === Number(event.target.value)) {
                this.setState({
                    active_data: this.state.data[i],
                    active_user: {
                        email: '',
                        username: '',
                        id: -1,
                        name: 'New User',
                        admin: false,
                    },
                    adding_new_user: true,
                    adding_existing_user: false,
                });
            }
        }
    }

    handleUserChange(event: React.ChangeEvent<HTMLSelectElement>) {
        for (let i = 0; i < this.state.active_data!.users.length; i++) {
            if (
                this.state.active_data!.users[i].id ===
                Number(event.target.value)
            ) {
                this.setState({
                    active_user: {
                        //create new user object to eliminate deep clone issues
                        email: this.state.active_data!.users[i].email,
                        username: this.state.active_data!.users[i].username,
                        id: this.state.active_data!.users[i].id,
                        name: this.state.active_data!.users[i].name,
                        admin: this.state.active_data!.users[i].admin,
                    },
                    adding_existing_user: false,
                    adding_new_user: false,
                    deleteU: 0,
                });
            } else if (-1 === Number(event.target.value)) {
                this.setState({
                    active_user: {
                        email: '',
                        username: '',
                        id: -1,
                        name: 'New User',
                        admin: false,
                    },
                    adding_new_user: true,
                    adding_existing_user: false,
                    deleteU: 0,
                });
            } else if (-2 === Number(event.target.value)) {
                this.setState({
                    active_user: {
                        email: '',
                        username: '',
                        id: -2,
                        name: 'AEU',
                        admin: false,
                    },
                    adding_existing_user: true,
                    adding_new_user: false,
                    deleteU: 0,
                });
            }
        }
    }

    render() {
        if (this.state.loading) {
            return <Loading desc="Loading.." />;
        } else {
            return (
                <div
                    ref={(el: any) => (this.myRef = el)}
                    className={styles.con}
                >
                    {this.state.deleteU === 1 ? ( //if the delete prompt is showing
                        <div className={styles.displaycon}>
                            <div className={styles.blurcon}></div>
                            <div className={styles.floatcon}>
                                <div>
                                    Are you sure you want to delete this user,
                                    this will delete the user from all stores!
                                </div>
                                <Button
                                    click={this.deleteUser}
                                    color={ButtonColor.RED}
                                    text="Delete"
                                />
                                <div>
                                    You can also remove this user from just your
                                    store with the button below
                                </div>
                                <Button
                                    click={this.removeUser}
                                    color={ButtonColor.RED}
                                    text="Remove"
                                />
                                <div>
                                    Or if you've made a terrible mistake, hit
                                    cancel to go back
                                </div>
                                <Button
                                    click={() => this.setState({ deleteU: 0 })}
                                    color={ButtonColor.DEFAULT}
                                    text="Cancel"
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className={styles.filtercon}>
                        <div>Store: </div>
                        <select
                            onChange={this.handleStoreChange}
                            value={this.state.active_data.store}
                        >
                            {this.state.data.map((node, index) => (
                                <option key={index} value={node.store}>
                                    {node.store}
                                </option>
                            ))}
                        </select>
                        <div>User:</div>
                        <select
                            onChange={this.handleUserChange}
                            value={this.state.active_user.id}
                        >
                            <option value="-1">New User</option>
                            <option value="-2">Add Existing User</option>
                            {this.state.active_data?.users.map(
                                (user, index) => (
                                    <option key={index} value={user.id}>
                                        {user.name}
                                    </option>
                                )
                            )}
                        </select>
                    </div>
                    {this.state.adding_existing_user ? (
                        <Adduser
                            store={this.state.active_data.store}
                            update={this.updateButStay}
                            load={this.setLoading}
                        />
                    ) : (
                        <User
                            create={this.state.adding_new_user}
                            userinfo={this.state.active_user}
                            store={this.state.active_data.store}
                            delete={this.deleteUser}
                            update={this.updateButStay}
                            load={this.setLoading}
                        />
                    )}
                </div>
            );
        }
    }
}

export default Admin;
