import React, { Component } from 'react';
import styles from './statdisplay.module.css';
import axios from 'axios';

type State = {
    loading: boolean;
    mtd: number | string;
    trend: number | string;
    mom: number | string;
};

type Props = {};

class StatDisplay extends Component<Props, State> {
    //in props check for install and if not return the install component instead of the login

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            mtd: '- -',
            trend: '- -',
            mom: '- -',
        };
    }

    componentDidMount() {
        axios
            .get('/api/user/gettotal')
            .then((response) => {
                if (parseFloat(response.data.total) < 0.8) {
                    //if total is smaller then 80 cents ignore it
                    this.setState({ loading: false, mtd: '- -', trend: '- -' });
                } else {
                    this.setState(
                        { mtd: parseFloat(response.data.total).toFixed(2) },
                        () => {
                            this.setState({
                                trend: this.calcTrend().toFixed(2),
                                loading: false,
                            });
                        }
                    );
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    calcTrend(): number {
        var date: Date = new Date();
        var time: Date = new Date(date.getTime());
        time.setMonth(date.getMonth() + 1);
        time.setDate(0);
        var daysleft: number =
            time.getDate() > date.getDate()
                ? time.getDate() - date.getDate()
                : 0;
        var totaldays: number = new Date(
            date.getFullYear(),
            date.getMonth() + 1,
            0
        ).getDate();
        var percentTopUp: number =
            1.0 + daysleft / totaldays / (1 - daysleft / totaldays);
        var trending: number = Number(this.state.mtd) * percentTopUp;
        return trending;
    }

    render() {
        if (this.state.loading) {
            return (
                <div className={styles.container}>
                    <div className={styles.mtd}>
                        <p>MTD</p>
                        <p>Loading...</p>
                    </div>
                    <div className={styles.trend}>
                        <p>Trend</p>
                        <p>Loading...</p>
                    </div>
                    <div className={styles.mom}>
                        <p>MoM</p>
                        <p>Loading...</p>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={styles.container}>
                    <div className={styles.mtd}>
                        <p>MTD</p>
                        <p>{this.state.mtd}</p>
                    </div>
                    <div className={styles.trend}>
                        <p>Trend</p>
                        <p>{this.state.trend}</p>
                    </div>
                    <div className={styles.mom}>
                        <p>MoM</p>
                        <p>{this.state.mom}</p>
                    </div>
                </div>
            );
        }
    }
}

export default StatDisplay;
