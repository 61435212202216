import React, { Component } from 'react';
import styles from './footer.module.css';

type State = {};

type Props = {
    type: number;
    sticky: boolean;
    footerClick: Function;
};

class Footer extends Component<Props, State> {
    //in props check for install and if not return the install component instead of the login

    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        var footStyle;
        if (this.props.sticky === false) {
            footStyle = styles.nonstickycon;
        } else {
            footStyle = styles.con;
        }
        if (this.props.type === 0) {
            return (
                <div className={footStyle}>
                    <div
                        className={styles.footeritem}
                        onClick={() => this.props.footerClick(0)}
                    >
                        Cancel
                    </div>
                </div>
            );
        } else if (this.props.type === 1) {
            return (
                <div className={footStyle}>
                    <div
                        className={styles.footeritem}
                        onClick={() => this.props.footerClick(0)}
                    >
                        Cancel
                    </div>
                    <div
                        className={styles.footeritem}
                        onClick={() => this.props.footerClick(3)}
                    >
                        Submit
                    </div>
                </div>
            );
        } else if (this.props.type === 2) {
            return (
                <div className={footStyle}>
                    <div
                        className={styles.footeritem}
                        onClick={() => this.props.footerClick(0)}
                    >
                        Cancel
                    </div>
                    <div
                        className={styles.footeritem}
                        onClick={() => this.props.footerClick(1)}
                    >
                        Add More
                    </div>
                    <div
                        className={styles.footeritem}
                        onClick={() => this.props.footerClick(2)}
                    >
                        Done
                    </div>
                </div>
            );
        } else if (this.props.type === 3) {
            return (
                <div className={footStyle}>
                    <div
                        className={styles.footeritem}
                        onClick={() => this.props.footerClick(4)}
                    >
                        Previous
                    </div>
                    <div
                        className={styles.footeritem}
                        onClick={() => this.props.footerClick(5)}
                    >
                        Next
                    </div>
                </div>
            );
        } else if (this.props.type === 4) {
            return (
                <div className={footStyle}>
                    <div
                        className={styles.footeritem}
                        onClick={() => this.props.footerClick(4)}
                    >
                        Previous
                    </div>
                    <div
                        className={styles.footeritem}
                        onClick={() => this.props.footerClick(6)}
                    >
                        Add A Lead
                    </div>
                    <div
                        className={styles.footeritem}
                        onClick={() => this.props.footerClick(5)}
                    >
                        Next
                    </div>
                </div>
            );
        } else if (this.props.type === 5) {
            return (
                <div className={footStyle}>
                    <div
                        className={styles.footeritem}
                        onClick={() => this.props.footerClick(4)}
                    >
                        Previous
                    </div>
                    <div
                        className={styles.footeritem}
                        onClick={() => this.props.footerClick(7)}
                    >
                        Add User
                    </div>
                    <div
                        className={styles.footeritem}
                        onClick={() => this.props.footerClick(5)}
                    >
                        Next
                    </div>
                </div>
            );
        }
    }
}

export default Footer;
