import React, { Component } from 'react';
import styles from './adduser.module.css';
import Button from '../button/Button';
import axios from 'axios';
import { ButtonColor } from '../../types';

type Props = {
    store: number;
    update: () => void;
    load: () => void;
};

type State = {
    searchvalue: string;
    searched: boolean;
    founduser: boolean;
    loading: boolean;
    user: string; //just the users name
    email: string;
    added: boolean; //indicates user has been added
};

class Adduser extends Component<Props, State> {
    //in props check for install and if not return the install component instead of the login

    constructor(props: Props) {
        super(props);

        this.state = {
            searchvalue: '',
            searched: false,
            founduser: false,
            loading: false,
            user: '',
            email: '',
            added: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.find = this.find.bind(this);
        this.addUser = this.addUser.bind(this);
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ searchvalue: event.target.value });
    }

    find() {
        //network call to find user matching username
        axios
            .post('/api/admin/findUser', {
                username: this.state.searchvalue,
            })
            .then((response) => {
                if (response.data.found) {
                    this.setState({
                        searched: true,
                        founduser: true,
                        added: false,
                        user: response.data.user.name,
                        email: response.data.user.email,
                    });
                } else {
                    this.setState({
                        searched: true,
                        founduser: false,
                        added: false,
                    });
                }
            })
            .catch((error) => {});
    }

    addUser() {
        axios
            .post('/api/admin/addUserToStore', {
                username: this.state.searchvalue,
                store: this.props.store,
            })
            .then((response) => {
                this.props.update();
                this.setState({ added: true });
            })
            .catch((error) => {});
        //network call to add user to store
    }

    render() {
        return (
            <div className={styles.con}>
                <div>Username</div>
                <input
                    type="number"
                    name="username"
                    value={this.state.searchvalue}
                    onChange={this.handleChange}
                    className={styles.iboxes}
                ></input>
                {this.state.added ? <div>User Added!</div> : null}
                {this.state.founduser && !this.state.added ? (
                    <div>
                        <p>Found the following user:</p>
                        <div>{this.state.user}</div>
                        <div>{this.state.email}</div>
                    </div>
                ) : null}
                {this.state.searched &&
                !this.state.founduser &&
                !this.state.added ? (
                    <div>No users found!</div>
                ) : null}
                <div className={styles.buttoncon}>
                    <Button
                        click={this.find}
                        color={ButtonColor.DEFAULT}
                        text="Find"
                    />
                    {this.state.founduser ? (
                        <Button
                            click={this.addUser}
                            color={ButtonColor.GREEN}
                            text="Add User"
                        />
                    ) : null}
                </div>
            </div>
        );
    }
}

export default Adduser;
