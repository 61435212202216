import React, { Component } from 'react';
import styles from './loggedincontainer.module.css';
import axios from 'axios';

import Dashboard from '../dashboard/Dashboard';
import Header from '../header/Header';
import Reports from '../reports/Reports';
import Leads from '../leads/Leads';
import History from '../history/History';
import Profile from '../profile/Profile';
import { Card } from '../cardview/CardView';
import Loading from '../loading/Loading';
import Admin from '../admin/Admin';
import Target from '../target/Target';

type State = {
    view: number;
    loading: boolean;
    cards: Array<Card>;
    admin: number;
    superadmin: number;
};

type Props = {
    updateView: (id: number) => void;
};

class LoggedInContainer extends Component<Props, State> {
    //in props check for install and if not return the install component instead of the login

    constructor(props: Props) {
        super(props);

        this.state = {
            view: 1,
            loading: true,
            cards: [],
            admin: 0,
            superadmin: 0,
        };

        this.updateView = this.updateView.bind(this);
    }

    componentDidMount() {
        axios
            .get('/api/user/rates')
            .then((response) => {
                this.setState({
                    view: 2,
                    cards: response.data.rates.cards,
                    admin: response.data.admin,
                    //TODO add super admin send inside the server call to rates, superadmin: response.data.superadmin,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    updateView(id: number): void {
        this.setState({ view: id });
    }

    render() {
        switch (this.state.view) {
            case 1:
                return <Loading desc={'Loading...'} />;
            case 2:
                return (
                    <div className={styles.mainContainer}>
                        <Header
                            admin={this.state.admin}
                            updateView={this.updateView}
                        />
                        <Dashboard cards={this.state.cards} />
                    </div>
                );
            case 3:
                return (
                    <div className={styles.mainContainer}>
                        <Header
                            admin={this.state.admin}
                            updateView={this.updateView}
                        />
                        <Reports />
                    </div>
                );
            case 4:
                return (
                    <div className={styles.mainContainer}>
                        <Header
                            admin={this.state.admin}
                            updateView={this.updateView}
                        />
                        <Leads />
                    </div>
                );
            case 5:
                return (
                    <div className={styles.mainContainer}>
                        <Header
                            admin={this.state.admin}
                            updateView={this.updateView}
                        />
                        <Target />
                    </div>
                );
            case 6:
                return (
                    <div className={styles.mainContainer}>
                        <Header
                            admin={this.state.admin}
                            updateView={this.updateView}
                        />
                        <History />
                    </div>
                );
            case 7:
                return (
                    <div className={styles.mainContainer}>
                        <Header
                            admin={this.state.admin}
                            updateView={this.updateView}
                        />
                        <Profile updateView={this.props.updateView} />
                    </div>
                );
            case 8:
                return (
                    <div className={styles.mainContainer}>
                        <Header
                            admin={this.state.admin}
                            updateView={this.updateView}
                        />
                        <Admin />
                    </div>
                );
            default:
                return <div className={styles.mainContainer}>Error</div>;
        }
    }
}

export default LoggedInContainer;
