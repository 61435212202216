import React, { Component } from 'react';
import styles from './user.module.css';
import { UserInfo, Target, ButtonColor } from '../../types';
import axios from 'axios';
import Button from '../button/Button';

type Props = {
    userinfo: UserInfo;
    store: number;
    delete: () => void;
    update: () => void;
    load: () => void;
    create: boolean; //are we looking at an actual user or a blank one, determines if target call should be made
};

type State = {
    user: UserInfo; //seeded with props data
    targets: Array<Target>;
    loading: boolean;
    userChanged: boolean;
    targetsChanged: boolean;
    password: string;
};

class User extends Component<Props, State> {
    //in props check for install and if not return the install component instead of the login

    constructor(props: Props) {
        super(props);

        this.state = {
            user: this.props.userinfo,
            targets: [],
            loading: false,
            userChanged: false,
            targetsChanged: false,
            password: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleTargetChange = this.handleTargetChange.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount() {
        if (this.props.create) return; //this is a new user so we dont have any targets for him
        axios
            .post('/api/admin/userTargets', {
                username: this.state.user.username,
                store: this.props.store,
            })
            .then((response) => {
                this.setState({
                    targets: response.data,
                });
                //this.props.update();
            })
            .catch((error) => {
                console.log('submit data error.');
            });
    }

    componentDidUpdate(prevProps: { userinfo: UserInfo; create: boolean }) {
        if (this.props.userinfo !== prevProps.userinfo) {
            this.setState({
                //update states if props changes
                user: this.props.userinfo, //update user
                targetsChanged: false, //make sure target tracking boolean is set back to false
                userChanged: false, //ditto for user tracking
                password: '',
            });
            if (this.props.create) {
                this.setState({ targets: [] });
            } //this is a new user so we dont have any targets for him
            else {
                axios
                    .post('/api/admin/userTargets', {
                        username: this.props.userinfo.username,
                        store: this.props.store,
                    })
                    .then((response) => {
                        this.setState({
                            targets: response.data,
                        });
                        //this.props.update();
                    })
                    .catch((error) => {
                        console.log('submit data error.');
                    });
            }
        }
    }

    handleTargetChange(event: React.ChangeEvent<HTMLInputElement>) {
        let delta = this.state.targets.slice();
        for (let i = 0; i < delta.length; i++) {
            if (delta[i].name === event.target.name) {
                delta[i].target = Number(event.target.value);
            }
        }
        this.setState({ targets: delta, targetsChanged: true });
    }

    handleChange(
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) {
        let tmp = this.state.user;
        switch (event.target.name) {
            case 'name':
                tmp.name = event.target.value;

                break;
            case 'email':
                tmp.email = event.target.value;

                break;
            case 'username':
                tmp.username = event.target.value;
                break;
            case 'admin':
                tmp.admin = !this.state.user.admin;
                break;
            case 'password':
                this.setState({ password: event.target.value });
                break;
        }
        this.setState({ user: tmp, userChanged: true });
    }

    save() {
        this.props.load();
        axios
            .post('/api/admin/editUser', {
                user: this.state.user,
                password: this.state.password,
                targets: this.state.targets,
                store: this.props.store,
                udelta: this.state.userChanged,
                tdelta: this.state.targetsChanged,
            })
            .then((response) => {
                this.props.update();
            })
            .catch((error) => {});
    }

    render() {
        return (
            <div>
                <div className={styles.editcon}>
                    <div>Name</div>
                    <input
                        type="text"
                        name="name"
                        value={this.state.user.name}
                        onChange={this.handleChange}
                        className={styles.iboxes}
                    ></input>
                    <div>Email</div>
                    <input
                        type="text"
                        name="email"
                        value={this.state.user.email}
                        onChange={this.handleChange}
                        className={styles.iboxes}
                    ></input>
                    <div>Username</div>
                    <input
                        type="text"
                        name="username"
                        value={this.state.user.username}
                        onChange={this.handleChange}
                        className={styles.iboxes}
                    ></input>
                    <div className={styles.adminbox}>
                        <div>Is Admin</div>
                        <input
                            type="checkbox"
                            name="admin"
                            className={styles.check}
                            checked={this.state.user.admin}
                            onChange={this.handleChange}
                        ></input>
                    </div>
                    <div>New Password</div>
                    <input
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={this.handleChange}
                        className={styles.iboxes}
                    ></input>
                </div>
                {this.state.targets.length > 0 ? (
                    <div className={styles.tarcon}>
                        <div className={styles.tartitle}>Targets</div>
                        {this.state.targets.map((target) => (
                            <div key={target.id} className={styles.targetcon}>
                                <div>{target.name}</div>
                                <input
                                    onChange={this.handleTargetChange}
                                    className={styles.tinput}
                                    type="number"
                                    name={target.name}
                                    value={target.target}
                                />
                            </div>
                        ))}
                    </div>
                ) : null}
                <div className={styles.buttoncon}>
                    <Button
                        click={this.save}
                        color={ButtonColor.DEFAULT}
                        text="Save"
                    />
                    {!this.props.create ? (
                        <Button
                            click={this.props.delete}
                            color={ButtonColor.RED}
                            text="Delete"
                        />
                    ) : null}
                </div>
            </div>
        );
    }
}

export default User;
