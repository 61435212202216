import React, { Component } from 'react';
import styles from './history.module.css';
import axios from 'axios';
import HistoryItem from '../historyitem/HistoryItem';
import Loading from '../loading/Loading';
import Footer from '../footer/Footer';

export interface SaleItem {
    id: number;
    saleID: number;
    display: boolean;
    displayName: string;
    value: number;
    valuetwo: number;
    valuethree: number;
    multiplier: number;
    worth: boolean;
    itemType: number;
}

export interface Sale {
    id: number;
    rep: number;
    store: number;
    type: string;
    date: string;
    items: Array<SaleItem>;
    total: number;
}

type State = {
    sales: Array<Sale>;
    viewableSales: Array<Sale>;
    subdivide: number;
    position: number;
    loading: boolean;
};

type Props = {};

class History extends Component<Props, State> {
    myRef: any = React.createRef();
    //in props check for install and if not return the install component instead of the login
    constructor(props: Props) {
        super(props);

        this.state = {
            sales: [],
            viewableSales: [],
            subdivide: 0,
            position: 0,
            loading: true,
        };

        this.removeASale = this.removeASale.bind(this);
        this.footerClick = this.footerClick.bind(this);
    }

    componentDidMount() {
        let { offsetHeight } = this.myRef;

        axios
            .get('/api/sales/getMTDSales')
            .then((response) => {
                let marginHeight = 210; //header plus some room for filters and stuff
                let sd = Math.floor((offsetHeight - marginHeight) / 80); //how many items can fit
                this.setState({
                    subdivide: sd, //how many fit
                    viewableSales: response.data.sales.slice(0, sd), //set the initial viewable items
                    sales: response.data.sales,
                    loading: false,
                });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(function () {});
    }

    footerClick(id: number) {
        var pos: number = 0;
        if (id === 4) {
            //prev
            pos = this.state.position;
            if (pos !== 0) {
                pos--;
                this.setState({
                    viewableSales: this.state.sales.slice(
                        pos * this.state.subdivide,
                        pos * this.state.subdivide + this.state.subdivide
                    ),
                    position: pos,
                });
            }
        } else if (id === 5) {
            var limit = Math.ceil(
                this.state.sales.length / this.state.subdivide - 1 // minus 1 because of array index starting at 0
            );
            pos = this.state.position;
            if (pos < limit) {
                pos++;
                this.setState({
                    viewableSales: this.state.sales.slice(
                        pos * this.state.subdivide,
                        pos * this.state.subdivide + this.state.subdivide
                    ),
                    position: pos,
                });
            }
        }
    }

    removeASale(saleID: number, store: number): void {
        console.log(store);
        axios
            .post('/api/sales/delsale', {
                id: saleID,
                store: store,
            })
            .then((response) => {})
            .catch((error) => {
                console.log('submit data error.');
            });
        //remove sale from server
        //trigger total recalc
        var tmpsales: Array<Sale> = this.state.sales.filter(function (sale) {
            return sale.id !== saleID;
        });
        this.setState({
            sales: tmpsales,
            viewableSales: tmpsales.slice(0, this.state.subdivide),
        });
    }

    render() {
        const { viewableSales } = this.state;
        if (this.state.loading) {
            return (
                <div
                    className={styles.loadingcon}
                    ref={(el: any) => (this.myRef = el)}
                >
                    <Loading desc={'Loading...'} />
                </div>
            );
        } else {
            return (
                <div className={styles.maincon}>
                    <div className={styles.filter}>
                        {/* Possible todo, add filter to history page*/}
                    </div>
                    <div className={styles.titleconn}>History</div>
                    <div className={styles.itemscon}>
                        {viewableSales.map((sale) => (
                            <HistoryItem
                                key={sale.id.toString()}
                                id={sale.id}
                                sale={sale}
                                date={new Date(sale.date).toLocaleDateString(
                                    'en-GB',
                                    {
                                        day: 'numeric',
                                        month: 'short',
                                        year: 'numeric',
                                    }
                                )}
                                name={sale.type}
                                total={sale.total}
                                remove={this.removeASale}
                            />
                        ))}
                    </div>
                    <Footer
                        type={3}
                        footerClick={this.footerClick}
                        sticky={true}
                    />
                </div>
            );
        }
    }
}

export default History;
