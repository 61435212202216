import React, { Component } from 'react';
import StatDisplay from '../statdisplay/StatDisplay';
import SaleDetailSelect from '../saledetailselect/SaleDetailSelect';

type State = {};

type Props = {
    display: boolean;
    saleDetailChange: (store: number, date: string) => void;
};

class SaleTitleContainer extends Component<Props, State> {
    //in props check for install and if not return the install component instead of the login

    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        if (this.props.display) {
            return <div></div>;
        } else {
            return (
                <div>
                    <StatDisplay />
                    <SaleDetailSelect
                        detailChange={this.props.saleDetailChange}
                    />
                </div>
            );
        }
    }
}

export default SaleTitleContainer;
