import React, { Component } from 'react';
import styles from './targetitem.module.css';
import { VictoryAnimation, VictoryPie, VictoryLabel } from 'victory';

type Props = {
    name: string;
    target: number;
    actual: number;
};

type State = {
    name: string;
    target: number;
    actual: number;
    percent: number;
    data: any;
    clicked: boolean;
};

class TargetItem extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            name: this.props.name,
            target: this.props.target,
            actual: this.props.actual,
            percent: (this.props.actual / this.props.target) * 100,
            data: this.getData((this.props.actual / this.props.target) * 100),
            clicked: false,
        };

        this.click = this.click.bind(this);
    }

    getData(percent: number) {
        return [
            { x: 1, y: percent },
            { x: 2, y: 100 - percent },
        ];
    }

    click() {
        this.setState({ clicked: !this.state.clicked });
    }

    render() {
        if (!this.state.clicked) {
            return (
                <div onClick={this.click}>
                    <svg viewBox="0 0 180 180" width="180px" height="180px">
                        <VictoryPie
                            standalone={false}
                            animate={{ duration: 1000 }}
                            width={180}
                            height={180}
                            padding={{
                                top: 10,
                                bottom: 10,
                                right: 10,
                                left: 10,
                            }}
                            data={this.state.data}
                            innerRadius={55}
                            cornerRadius={0}
                            labels={() => null}
                            style={{
                                data: {
                                    fill: ({ datum }) => {
                                        const color =
                                            datum.y > 30 ? 'green' : 'red';
                                        return datum.x === 1 ? color : 'grey';
                                    },
                                },
                            }}
                        />
                        <VictoryAnimation
                            duration={1000}
                            data={this.state.data}
                        >
                            {(newProps) => {
                                return (
                                    <VictoryLabel
                                        textAnchor="middle"
                                        verticalAnchor="middle"
                                        x={90}
                                        y={90}
                                        text={`${
                                            this.state.name
                                        } \n ${Math.round(
                                            Number(this.state.percent)
                                        )}%`}
                                        style={{
                                            fontSize: 16,
                                            fontFamily: 'Raleway',
                                        }}
                                    />
                                );
                            }}
                        </VictoryAnimation>
                    </svg>
                </div>
            );
        } else {
            return (
                <div onClick={this.click}>
                    <svg viewBox="0 0 180 180" width="180px" height="180px">
                        <VictoryPie
                            standalone={false}
                            animate={{ duration: 1000 }}
                            width={180}
                            height={180}
                            data={this.state.data}
                            innerRadius={55}
                            padding={{
                                top: 10,
                                bottom: 10,
                                right: 10,
                                left: 10,
                            }}
                            cornerRadius={0}
                            labels={() => null}
                            style={{
                                data: {
                                    fill: ({ datum }) => {
                                        const color =
                                            datum.y > 30 ? 'green' : 'red';
                                        return datum.x === 1 ? color : 'grey';
                                    },
                                },
                            }}
                        />
                        <VictoryAnimation
                            duration={1000}
                            data={this.state.data}
                        >
                            {(newProps) => {
                                return (
                                    <VictoryLabel
                                        textAnchor="middle"
                                        verticalAnchor="middle"
                                        x={90}
                                        y={90}
                                        text={`${this.state.name} \n ${this.state.actual} of ${this.state.target}`}
                                        style={{
                                            fontSize: 16,
                                            fontFamily: 'Raleway',
                                        }}
                                    />
                                );
                            }}
                        </VictoryAnimation>
                    </svg>
                </div>
            );
        }
    }
}

export default TargetItem;
