//user info object
export interface UserInfo {
    email: string;
    username: string;
    id: number;
    name: string;
    admin: boolean;
}

export interface Target {
    id: number;
    name: string;
    itemType: number;
    count: number;
    targeted: boolean;
    target: number;
    statID: number;
}

export enum ButtonColor {
    GREEN = 'lightseagreen',
    DEFAULT = '#00a0b7',
    RED = 'lightcoral',
}
