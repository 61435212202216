import React, { Component } from 'react';
import axios from 'axios';
import styles from './reports.module.css';
import Loading from '../loading/Loading';

interface reportItem {
    displayName: string;
    count: number;
    color: string;
}

interface Report {
    name: string;
    path: string;
}

type State = {
    reportrunning: boolean;
    reportresults: boolean;
    reportdata: Array<reportItem>;
    reporttype: string;
    stores: Array<number>;
    activestore: number;
    reports: Array<Report>;
    loading: boolean;
};

type Props = {};

class Reports extends Component<Props, State> {
    //in props check for install and if not return the install component instead of the login

    constructor(props: Props) {
        super(props);

        this.state = {
            reportrunning: false,
            reportresults: false,
            reportdata: [],
            reporttype: '',
            stores: [],
            activestore: 0,
            reports: [],
            loading: true,
        };

        this.handlechange = this.handlechange.bind(this);
        this.handlechange2 = this.handlechange2.bind(this);
        this.runReport = this.runReport.bind(this);
    }

    componentDidMount() {
        axios.get('/api/store/getMyStores').then((response) => {
            this.setState({
                stores: response.data.stores,
                activestore: Number(response.data.stores[0]),
            });
            axios.get('/api/report/getReports').then((response) => {
                this.setState({
                    reports: response.data.reports,
                    reporttype: response.data.reports[0].path,
                    loading: false,
                });
            });
        });
    }

    handlechange(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({ reporttype: event.target.value });
    }

    handlechange2(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({ activestore: Number(event.target.value) });
    }

    runReport() {
        this.setState({ reportrunning: true });
        axios
            .post(this.state.reporttype, {
                store: this.state.activestore,
            })
            .then((response) => {
                this.setState({
                    reportrunning: false,
                    reportresults: true,
                    reportdata: response.data,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    render() {
        const { reportdata, stores, loading } = this.state;
        if (loading) {
            return <Loading desc="Loading..."></Loading>;
        } else {
            return (
                <div className={styles.con}>
                    <p className={styles.head}>
                        Choose a report below and then hit run.
                    </p>
                    <div className={styles.repcon}>
                        <div className={styles.inputbox}>
                            <span>▼</span>
                            <select
                                className={styles.input}
                                id="stores"
                                onChange={this.handlechange2}
                            >
                                {stores.map((item, index) => (
                                    <option key={index.toString()} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={styles.inputbox}>
                            <span>▼</span>
                            <select
                                className={styles.input}
                                id="stores"
                                onChange={this.handlechange}
                            >
                                {this.state.reports.map((item, index) => (
                                    <option
                                        key={index.toString()}
                                        value={item.path}
                                    >
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={styles.btn} onClick={this.runReport}>
                            Run
                        </div>
                    </div>
                    <table>
                        {reportdata.map((item, index) => (
                            <tr
                                key={index.toString()}
                                style={{ backgroundColor: item.color }}
                            >
                                <td>{item.displayName}</td>
                                <td>{item.count}</td>
                            </tr>
                        ))}
                    </table>
                </div>
            );
        }
    }
}

export default Reports;
