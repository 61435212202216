import React, { Component } from 'react';
import styles from './leaditem.module.css';
import axios from 'axios';

type State = {
    lead: LeadData;
    added: string;
    open: boolean;
    update: boolean;
    delete: boolean;
    complete: boolean;
    level: number;
    ninfo: string;
};

type Props = {
    lead: LeadData;
    leadsNeedToUpdate: () => void;
};

export interface LeadData {
    id: number;
    rep: number;
    type: string;
    status: string;
    priority: number;
    completed: boolean;
    info: string;
    date: string;
}

class LeadItem extends Component<Props, State> {
    //in props check for install and if not return the install component instead of the login

    constructor(props: Props) {
        super(props);

        this.state = {
            lead: this.props.lead,
            added: this.props.lead.date,
            open: false,
            update: false,
            delete: false,
            complete: false,
            level: this.props.lead.priority,
            ninfo: this.props.lead.info,
        };

        this.handleClick = this.handleClick.bind(this);
        this.btnDel = this.btnDel.bind(this);
        this.btnUpdate = this.btnUpdate.bind(this);
        this.btnComp = this.btnComp.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.cancel = this.cancel.bind(this);
        this.sendDelete = this.sendDelete.bind(this);
        this.sendUpdate = this.sendUpdate.bind(this);
        this.sendComplete = this.sendComplete.bind(this);
    }

    handleClick() {
        this.setState({ open: !this.state.open });
    }

    btnDel() {
        this.setState({
            update: false,
            delete: true,
            complete: false,
            open: false,
        });
    }

    btnUpdate() {
        this.setState({
            update: true,
            delete: false,
            complete: false,
            open: false,
        });
    }

    btnComp() {
        this.setState({
            update: false,
            delete: false,
            complete: true,
            open: false,
        });
    }

    handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({ ninfo: event.target.value }); //setState finds and matches input values to the state valus defined in constructor
    }

    cancel() {
        this.setState({
            update: false,
            delete: false,
            complete: false,
            open: false,
        });
    }

    sendDelete() {
        axios
            .post('/api/leads/dellead', {
                id: this.state.lead.id,
            })
            .then((response) => {
                this.props.leadsNeedToUpdate();
            })
            .catch((error) => {});
    }

    sendUpdate() {
        axios
            .post('/api/leads/updatelead', {
                id: this.state.lead.id,
                info: this.state.ninfo,
            })
            .then((response) => {
                this.setState({
                    update: false,
                    delete: false,
                    complete: false,
                    open: false,
                });
                this.props.leadsNeedToUpdate();
            })
            .catch((error) => {});
    }

    sendComplete() {
        axios
            .post('/api/leads/setcomplete', {
                id: this.state.lead.id,
            })
            .then((response) => {
                this.setState({
                    update: false,
                    delete: false,
                    complete: false,
                    open: false,
                });
                this.props.leadsNeedToUpdate();
            })
            .catch((error) => {});
    }

    render() {
        let level: string;
        if (this.props.lead.completed) {
            level = 'Completed';
        } else {
            level = 'Priority: ' + this.state.level;
        }
        if (
            !this.state.open &&
            !this.state.update &&
            !this.state.delete &&
            !this.state.complete
        ) {
            return (
                <div className={styles.maincon}>
                    <div className={styles.namecon} onClick={this.handleClick}>
                        <div className={styles.email}>
                            <div>{this.state.lead.type}</div>
                            <div className={styles.pcon}>{level}</div>
                        </div>
                        <div className={styles.peopleid}>
                            {this.state.lead.status}
                        </div>
                        <div className={styles.peopleid}>
                            {this.state.added}
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.open) {
            return (
                <div className={styles.openitem}>
                    <div className={styles.displaycon}>
                        <div className={styles.blurcon}></div>
                        <div className={styles.floatcon}>
                            <div className={styles.email}>
                                <div>{this.state.lead.type}</div>
                                <div>{level}</div>
                            </div>
                            <div className={styles.peopleid}>
                                {this.state.lead.status}
                            </div>
                            <div className={styles.peopleid}>
                                {this.state.added}
                            </div>
                            <textarea
                                className={styles.opencon}
                                name="ninfo"
                                value={this.state.ninfo}
                                onChange={this.handleChange}
                            ></textarea>
                            <div className={styles.buttoncon}>
                                <div
                                    className={styles.targetbutton}
                                    onClick={this.sendUpdate}
                                >
                                    Update
                                </div>
                                <div
                                    className={styles.editbutton}
                                    onClick={this.sendComplete}
                                >
                                    Complete
                                </div>
                                <div
                                    className={styles.delbutton}
                                    onClick={this.sendDelete}
                                >
                                    Delete
                                </div>
                                <div
                                    className={styles.cancelbutton}
                                    onClick={this.cancel}
                                >
                                    Cancel
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.maincon}>
                        <div
                            className={styles.namecon}
                            onClick={this.handleClick}
                        >
                            <div className={styles.email}>
                                <div>{this.state.lead.type}</div>
                                <div className={styles.pcon}>{level}</div>
                            </div>
                            <div className={styles.peopleid}>
                                {this.state.lead.status}
                            </div>
                            <div className={styles.peopleid}>
                                {this.state.added}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default LeadItem;
