import React, { Component } from 'react';
import styles from './dashboard.module.css';
import CardView, { Card } from '../cardview/CardView';
import SaleTitleContainer from '../saletitlecontainer/SaleTitleContainer';

type State = {
    cards: Array<Card>;
    view: number;
    saleStarted: boolean;
    store: number;
    date: string;
};

type Props = {
    cards: Array<Card>;
};

class Dashboard extends Component<Props, State> {
    //in props check for install and if not return the install component instead of the login

    constructor(props: Props) {
        super(props);
        this.state = {
            cards: this.props.cards,
            view: 0,
            saleStarted: false,
            store: 0,
            date: '',
        };

        this.saleFlip = this.saleFlip.bind(this);
        this.saleDetailChange = this.saleDetailChange.bind(this);
    }

    saleFlip() {
        this.setState({ saleStarted: !this.state.saleStarted }); //flip value
    }

    saleDetailChange(store: number, date: string): void {
        this.setState({ store: store, date: date });
    }

    render() {
        return (
            <div className={styles.container}>
                <SaleTitleContainer
                    saleDetailChange={this.saleDetailChange}
                    display={this.state.saleStarted}
                />
                <CardView
                    cards={this.state.cards}
                    saleFlip={this.saleFlip}
                    date={this.state.date}
                    store={this.state.store}
                />
            </div>
        );
    }
}

export default Dashboard;
