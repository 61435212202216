import React, { Component } from 'react';
import axios from 'axios';
import styles from './target.module.css';
import Loading from '../loading/Loading';
import TargetItem from '../targetitem/TargetItem';
import Footer from '../footer/Footer';

interface tnode {
    id: number;
    name: string;
    itemType: number;
    count: number;
    targeted: boolean;
    target: number;
    statID: number;
}

type Props = {};

type State = {
    targets: Array<tnode>;
    vtargets: Array<tnode>;
    loading: boolean;
    subdivide: number;
    position: number;
};

class Target extends Component<Props, State> {
    myRef: any = React.createRef();
    constructor(props: Props) {
        super(props);

        this.state = {
            targets: [],
            vtargets: [],
            subdivide: 0,
            position: 0,
            loading: true,
        };

        this.footerClick = this.footerClick.bind(this);
    }

    componentDidMount() {
        let { offsetHeight } = this.myRef;
        axios
            .post('/api/user/getTargets', {
                store: 5174,
            })
            .then((response) => {
                let marginHeight = 150; //header plus some room for filters and stuff
                let sd = Math.floor((offsetHeight - marginHeight) / 180); //how many items can fit

                this.setState({
                    subdivide: sd * 2, //how many fit
                    vtargets: response.data.slice(0, sd * 2), //set the initial viewable items

                    targets: response.data,
                    loading: false,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    footerClick(id: number) {
        var pos: number = 0;
        if (id === 4) {
            //prev
            pos = this.state.position;
            if (pos !== 0) {
                pos--;
                this.setState({
                    vtargets: this.state.targets.slice(
                        pos * this.state.subdivide,
                        pos * this.state.subdivide + this.state.subdivide
                    ),
                    position: pos,
                });
            }
        } else if (id === 5) {
            var limit = Math.ceil(
                this.state.targets.length / this.state.subdivide - 1 // minus 1 because of array index starting at 0
            );
            pos = this.state.position;
            if (pos < limit) {
                pos++;
                this.setState({
                    vtargets: this.state.targets.slice(
                        pos * this.state.subdivide,
                        pos * this.state.subdivide + this.state.subdivide
                    ),
                    position: pos,
                });
            }
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <div
                    className={styles.loadingcon}
                    ref={(el: any) => (this.myRef = el)}
                >
                    <Loading desc="loading..."></Loading>;
                </div>
            );
        } else {
            return (
                <div className={styles.maincon}>
                    <div className={styles.titleconn}>TARGETS</div>
                    <div className={styles.tarcon}>
                        {this.state.vtargets.map((target) => (
                            <TargetItem
                                key={target.id.toString()}
                                name={target.name}
                                target={target.target}
                                actual={target.count}
                            />
                        ))}
                    </div>
                    <Footer
                        type={3}
                        footerClick={this.footerClick}
                        sticky={true}
                    />
                </div>
            );
        }
    }
}

export default Target;
