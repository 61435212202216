import React, { Component } from 'react';
import styles from './header.module.css';
import Menu from '../menu/Menu';

type State = {
    extended: boolean;
};

type Props = {
    updateView: (id: number) => void;
    admin: number;
};

class Header extends Component<Props, State> {
    //in props check for install and if not return the install component instead of the login

    constructor(props: Props) {
        super(props);

        this.state = {
            extended: false,
        };

        this.openMenu = this.openMenu.bind(this);
        this.goToMainPage = this.goToMainPage.bind(this);
        this.updateView = this.updateView.bind(this);
    }

    openMenu() {
        this.setState({ extended: !this.state.extended });
    }

    goToMainPage() {
        this.setState({ extended: false }); //make sure menu is closed if were going back to the main page
        this.props.updateView(2);
    }

    updateView(id: number) {
        this.setState({ extended: false });
        this.props.updateView(id);
    }

    render() {
        if (!this.state.extended) {
            return (
                <div className={styles.container}>
                    <div className={styles.logo} onClick={this.openMenu}></div>
                    <div className={styles.title} onClick={this.goToMainPage}>
                        SalesTrackr
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className={styles.container}>
                        <div
                            className={styles.logo}
                            onClick={this.openMenu}
                        ></div>
                        <div
                            className={styles.title}
                            onClick={this.goToMainPage}
                        >
                            SalesTrackr
                        </div>
                    </div>
                    <Menu
                        updateView={this.updateView}
                        openMenu={this.openMenu}
                        admin={this.props.admin}
                    />
                </div>
            );
        }
    }
}

export default Header;
