import React from 'react';
import styles from './install.module.css';
import share from '../../static/share.svg';

const Install = () => {
    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div className={styles.logo}></div>
            </div>
            <div className={styles.container}>
                <p className={styles.text}>
                    This tool was designed as a Progressive Web App, as such
                    please follow the instructions below before use.
                </p>
                <p className={styles.text}>
                    To install this Web App on to your iPhone or iPad press{' '}
                    <img
                        className={styles.image}
                        alt="iOS share icon"
                        src={share}
                    ></img>{' '}
                    and then Add to Home Screen.
                </p>
                <p className={styles.text}>
                    To install on any Android device open the menu and press Add
                    to Home screen
                </p>
            </div>
        </div>
    );
};

export default Install;
