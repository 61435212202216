import React, { Component } from 'react';
import styles from './cardview.module.css';
import CardItem from '../card/CardItem';
import Footer from '../footer/Footer';
import TitleBox from '../titlebox/TitleBox';
import SaleSummary from '../salesummary/SaleSummary';

type Props = {
    cards: Array<Card>;
    saleFlip: Function;
    store: number;
    date: string;
};

type State = {
    activeCard: Card;
    cards: Array<Card>;
    rendered: boolean;
    width: number;
    height: number;
    circleDiameter: number;
    viewableCards: Array<Card>;
    viewableCardCount: number;
    summaryVisible: boolean;
    currentSale: Array<Record>;
    mainText: string;
    subText: string;
    chained: boolean;
    saleStarted: boolean;
    activeTextBoxValue: string;
    saleSummaryViewable: boolean;
    lengthy: boolean;
};

export interface Record {
    id: number;
    display: boolean;
    displayName: string;
    value: number;
    valuetwo: number;
    valuethree: number;
    multiplier: number;
    worth: boolean;
    itemType: number;
    category: string;
}

export interface Card {
    id: number;
    name: string;
    cards: Array<number>;
    footer: number;
    sum: string;
    desc: string;
    type: number;
    textBox: boolean;
    worth: boolean;
    valuetx: string;
    multiplier: number;
    value: number;
    valuetwo: number;
    valuethree: number;
    displayName: string;
    display: boolean;
    itemType: number;
    selected: boolean;
    allowMultiSelect: boolean;
    category: string;
}

class CardView extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            activeCard: this.props.cards[0],
            cards: this.props.cards,
            rendered: false,
            width: 0,
            height: 0,
            circleDiameter: 0,
            viewableCards: [],
            viewableCardCount: 0,
            summaryVisible: true,
            currentSale: [],
            mainText: '',
            subText: '',
            chained: false,
            saleStarted: false,
            activeTextBoxValue: '',
            saleSummaryViewable: false,
            lengthy: false,
        };

        this.onClick = this.onClick.bind(this);
        this.footerClick = this.footerClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    container: any = null;

    componentDidMount() {
        this.setState({
            rendered: true,
            width: this.container.offsetWidth,
            height: this.container.offsetHeight,
        });
        this.setViewableCards(0);
    }

    setViewableCards(id: number) {
        //take the id of the card that was clicked use this to set footer salessummary and header

        //get card that was clicked and get the cardIDs for the next cards
        var clickedCard: Card = this.state.cards.find(function (card) {
            return card.id === id;
        })!;

        this.setState({ activeCard: clickedCard });

        //if === 0 is the main menu, otherwise hide the sales summary component
        if (id === 0) {
            this.setState({ summaryVisible: true });
        } else {
            this.setState({ summaryVisible: false });
            //save clicked item into current sale object
            this.setState({
                currentSale: [
                    ...this.state.currentSale,
                    this.buildSaleRecord(clickedCard),
                ],
            });
        }

        var newViewableCards: Array<Card> = [];
        var newViewableCardIDs: Array<number> = clickedCard.cards;

        //set the text for the card item
        this.setState({
            mainText: clickedCard.sum,
            subText: clickedCard.desc,
        });

        //get cards to be displayed
        for (let i = 0; i < newViewableCardIDs.length; i++) {
            newViewableCards.push(
                this.state.cards.find(function (card) {
                    return card.id === newViewableCardIDs[i];
                })!
            );
        }
        var lng = newViewableCards.length >= 5;
        this.setState({
            viewableCards: newViewableCards,
            viewableCardCount: newViewableCards.length,
            lengthy: lng,
        });
    }

    footerClick(id: number) {
        switch (id) {
            case 0: //cancel
                this.props.saleFlip(); //make the stats and sale details component visible
                this.setViewableCards(0); //bring back main card menu
                this.setState({
                    currentSale: [],
                    saleStarted: false,
                    activeTextBoxValue: '',
                    saleSummaryViewable: false,
                }); //reset state components
                break;
            case 1: //chain
                this.setViewableCards(0);
                this.setState({ activeTextBoxValue: '' }); //reset state components
                if (this.state.activeCard.allowMultiSelect) {
                    let selectedRecords: Array<Record> = [];
                    for (
                        let i: number = 0;
                        i < this.state.viewableCards.length;
                        i++
                    ) {
                        if (this.state.viewableCards[i].selected) {
                            selectedRecords.push(
                                this.buildSaleRecord(
                                    this.state.viewableCards[i]
                                )
                            );
                        }
                    }
                    this.setState({
                        currentSale: [
                            ...this.state.currentSale,
                            ...selectedRecords,
                        ],
                    });
                } else if (
                    this.state.viewableCardCount === 1 &&
                    this.state.viewableCards[0].textBox
                ) {
                    this.setState({
                        currentSale: [
                            ...this.state.currentSale,
                            this.buildSaleRecord(this.state.viewableCards[0]),
                        ],
                    });
                }
                break;
            case 2: //done
                if (this.state.activeCard.allowMultiSelect) {
                    let selectedRecords: Array<Record> = [];
                    for (
                        let i: number = 0;
                        i < this.state.viewableCards.length;
                        i++
                    ) {
                        if (this.state.viewableCards[i].selected) {
                            selectedRecords.push(
                                this.buildSaleRecord(
                                    this.state.viewableCards[i]
                                )
                            );
                        }
                    }
                    this.setState({
                        currentSale: [
                            ...this.state.currentSale,
                            ...selectedRecords,
                        ],
                    });
                } else if (
                    this.state.viewableCardCount === 1 &&
                    this.state.viewableCards[0].textBox
                ) {
                    this.setState({
                        currentSale: [
                            ...this.state.currentSale,
                            this.buildSaleRecord(this.state.viewableCards[0]),
                        ],
                    });
                }
                this.setState({ saleSummaryViewable: true });
                break;
            case 3:
                break;
            default:
        }
    }

    //onChange
    handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
        console.log(e);
        this.setState({ activeTextBoxValue: e.target.value });
    }

    //onSubmit
    handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
        //key down in an input box inside a card
        e.preventDefault();
        this.footerClick(2); //mark as done
    }

    circleSize(circleNumber: number): void {
        var area: number = this.state.width * this.state.height;
        var circleArea: number = area / circleNumber;
        var circleDiameter: number = Math.sqrt(circleArea);
        this.setState({ circleDiameter: circleDiameter });
    }

    buildSaleRecord(card: Card): Record {
        let vtwo: number;
        if (!card.textBox) {
            vtwo = card.valuetwo;
        } else {
            vtwo = Number(this.state.activeTextBoxValue);
        }

        let res: Record = {
            id: card.id,
            display: card.display,
            displayName: card.displayName,
            value: card.value,
            valuetwo: vtwo,
            valuethree: card.valuethree,
            multiplier: card.multiplier,
            worth: card.worth,
            itemType: card.itemType,
            category: card.category,
        };

        return res;
    }

    onClick(id: number) {
        var clickedCard: Card = this.state.viewableCards.find(function (
            card,
            index
        ) {
            return card.id === id;
        })!;

        if (clickedCard.textBox) {
            return;
        }

        if (this.state.activeCard.allowMultiSelect) {
            let selected: boolean = !clickedCard.selected;
            this.setState({
                viewableCards: this.state.viewableCards.map((el) =>
                    el.id === id ? Object.assign({}, el, { selected }) : el
                ),
            });
            return;
        }

        this.setViewableCards(id);
        if (this.state.saleStarted === false)
            //sale is starting to hide the menu
            this.props.saleFlip();
        this.setState({ saleStarted: true });
    }

    render() {
        if (
            this.state.rendered &&
            !this.state.saleStarted &&
            !this.state.saleSummaryViewable
        ) {
            return (
                <div className={styles.container}>
                    <TitleBox
                        desc={this.state.activeCard.desc}
                        sum={this.state.activeCard.sum}
                    />
                    <div className={styles.viewcon}>
                        {this.state.viewableCards.map((card) => (
                            <CardItem
                                key={card.id}
                                id={card.id}
                                name={card.name}
                                onClick={this.onClick}
                                textbox={card.textBox}
                                selected={card.selected}
                                handleChange={this.handleChange}
                                handleSubmit={this.handleSubmit}
                                lengthy={this.state.lengthy}
                            />
                        ))}
                    </div>
                </div>
            );
        } else if (
            this.state.rendered &&
            this.state.saleStarted &&
            !this.state.saleSummaryViewable
        ) {
            return (
                <div className={styles.container}>
                    <TitleBox
                        desc={this.state.activeCard.desc}
                        sum={this.state.activeCard.sum}
                    />
                    <div className={styles.viewcon}>
                        {this.state.viewableCards.map((card) => (
                            <CardItem
                                key={card.id}
                                id={card.id}
                                name={card.name}
                                onClick={this.onClick}
                                textbox={card.textBox}
                                selected={card.selected}
                                handleChange={this.handleChange}
                                handleSubmit={this.handleSubmit}
                                lengthy={this.state.lengthy}
                            />
                        ))}
                    </div>
                    <Footer
                        type={this.state.activeCard.footer}
                        footerClick={this.footerClick}
                        sticky={true}
                    />
                </div>
            );
        } else if (
            this.state.rendered &&
            this.state.saleStarted &&
            this.state.saleSummaryViewable
        ) {
            return (
                <SaleSummary
                    store={this.props.store}
                    date={this.props.date}
                    sale={this.state.currentSale}
                    footerClick={this.footerClick}
                />
            );
        } else {
            return (
                <div
                    className={styles.viewcalc}
                    ref={(el: any) => (this.container = el)}
                ></div>
            );
        }
    }
}

export default CardView;
