import React, { Component } from 'react';
import styles from './historyitem.module.css';
import { Sale } from '../history/History';
import HistorySubItem from '../historysubitem/HistorySubItem';

type State = {
    open: boolean;
};

type Props = {
    id: number;
    sale: Sale;
    date: string;
    name: string;
    total: number;
    remove: (id: number, store: number) => void;
};

class HistoryItem extends Component<Props, State> {
    //in props check for install and if not return the install component instead of the login

    constructor(props: Props) {
        super(props);

        this.state = {
            open: false,
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState({ open: !this.state.open });
    }

    render() {
        if (this.state.open) {
            return (
                <div>
                    <div
                        className={styles.itemconopen}
                        onClick={this.handleClick}
                    >
                        <div className={styles.titlecon}>
                            <div className={styles.titlel}>
                                {this.props.date}
                            </div>
                            <div className={styles.titlec}>
                                {this.props.name}
                            </div>
                        </div>
                        <div className={styles.titler}>
                            ${this.props.total.toFixed(0)}
                        </div>
                    </div>
                    <div className={styles.displaycon}>
                        <div className={styles.floatcon}>
                            <div className={styles.titlebox}>
                                <div className={styles.titlebox2}>
                                    <div className={styles.saletype}>
                                        {this.props.name}
                                    </div>
                                    <div>{this.props.date}</div>
                                    <div>Store: {this.props.sale.store}</div>
                                </div>
                            </div>
                            <HistorySubItem items={this.props.sale.items} />
                            <hr></hr>
                            <div className={styles.titleboxtotal}>
                                <div>Total:</div>
                                <div>${this.props.total.toFixed(2)}</div>
                            </div>
                            <div className={styles.titlebox}>
                                <div
                                    className={styles.button}
                                    onClick={() =>
                                        this.props.remove(
                                            this.props.id,
                                            this.props.sale.store
                                        )
                                    }
                                >
                                    Remove
                                </div>{' '}
                                <div
                                    className={styles.button2}
                                    onClick={this.handleClick}
                                >
                                    Close
                                </div>
                            </div>
                        </div>
                        <div className={styles.blurcon}></div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className={styles.itemcon} onClick={this.handleClick}>
                        <div className={styles.titlecon}>
                            <div className={styles.titlel}>
                                {this.props.date}
                            </div>
                            <div className={styles.titlec}>
                                {this.props.name}
                            </div>
                        </div>
                        <div className={styles.titler}>
                            ${this.props.total.toFixed(0)}
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default HistoryItem;
