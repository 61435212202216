import React, { Component } from 'react';
import styles from './salesummary.module.css';
import { Record } from '../cardview/CardView';
import Footer from '../footer/Footer';
import Loading from '../loading/Loading';
import axios from 'axios';

type State = {
    submitted: boolean;
    total: number;
    display: Array<Record>;
};

type Props = {
    store: number;
    date: string;
    sale: Array<Record>;
    footerClick: (id: number) => void;
};

class SaleSummary extends Component<Props, State> {
    //in props check for install and if not return the install component instead of the login

    constructor(props: Props) {
        super(props);

        let display: Array<Record> = this.props.sale.filter(
            (item) => item.display === true
        );
        let total: number = 0;

        for (let i: number = 0; i < display.length; i++) {
            let t: Record = display[i];
            total += t.value * t.multiplier * t.valuetwo;
        }

        this.state = {
            submitted: false,
            total: total,
            display: display,
        };

        this.footerChoice = this.footerChoice.bind(this);
    }

    footerChoice(id: number) {
        if (id === 0) {
            this.props.footerClick(0); //send cancel to cardviewer
        } else if (id === 3) {
            this.setState({ submitted: true });
            var now = new Date();
            var date: string = this.props.date.concat(
                ' ',
                now.getHours().toString(),
                ':',
                now.getMinutes().toString(),
                ':',
                now.getSeconds().toString()
            );
            axios
                .post('/api/sales/addsale', {
                    sale: this.props.sale,
                    store: this.props.store, //need to be changed later
                    total: this.state.total,
                    date: date,
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.props.footerClick(0); //sale stored on server send state back to normal
                    }
                })
                .catch((error) => {});
        }
    }

    render() {
        if (!this.state.submitted) {
            return (
                <div>
                    <h1 className={styles.maintitle}>
                        Sale Commission Summary
                    </h1>
                    <div className={styles.itemcontainer}>
                        {this.state.display.map((item, index) => (
                            <div key={index} className={styles.rowcontainer}>
                                <div className={styles.name}>
                                    {item.displayName}
                                </div>
                                <div className={styles.value}>
                                    $
                                    {(
                                        item.value *
                                        item.multiplier *
                                        item.valuetwo
                                    ).toFixed(2)}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.total}>
                        Total: ${this.state.total.toFixed(2)}
                    </div>
                    <Footer
                        type={1}
                        footerClick={this.footerChoice}
                        sticky={true}
                    />
                </div>
            );
        } else if (this.state.submitted) {
            return <Loading desc={'Sending data to server...'} />;
        }
    }
}

export default SaleSummary;
