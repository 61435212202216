import React, { Component } from 'react';
import axios from 'axios';
import styles from './login.module.css';

type State = {
    username: string;
    email: string;
    password: string;
    loginerror: boolean;
};

type Props = {
    updateView: (id: number) => void;
};

class Login extends Component<Props, State> {
    //in props check for install and if not return the install component instead of the login

    constructor(Props: Readonly<Props>) {
        super(Props);

        this.state = {
            username: '',
            email: '',
            password: '',
            loginerror: false
        };

        this.handleChangePN = this.handleChangePN.bind(this);
        this.handleChangeEA = this.handleChangeEA.bind(this);
        this.handleChangePA = this.handleChangePA.bind(this);
        this.login = this.login.bind(this);
    }

    login(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        axios
            .post('/api/auth/login', {
                username: this.state.username,
                email: this.state.email,
                password: this.state.password
            }) //admin things
            .then(response => {
                if (response.data.loggedin === true) {
                    this.props.updateView(2);
                    //login was good pass back prop to Container to change to dashboard view
                } else {
                    this.setState({ loginerror: true });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    handleChangePN(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ username: e.target.value });
    }

    handleChangeEA(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ email: e.target.value });
    }

    handleChangePA(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ password: e.target.value });
    }

    render() {
        let errorStyle = styles.error;
        if (this.state.loginerror) {
            errorStyle = styles.errorsee;
        }
        return (
            <div className={styles.container}>
                <div className={styles.logo}></div>
                <div className={styles.title}>SalesTrackr</div>
                <div className={errorStyle}>
                    Error: Incorrect Login Information
                </div>
                <form className={styles.formcontainer} onSubmit={this.login}>
                    <div className={styles.label}>Person Number</div>
                    <input
                        className={styles.textboxes}
                        onChange={this.handleChangePN}
                        name="username"
                        inputMode="decimal"
                        type="number"
                    ></input>
                    <div className={styles.label}>Email Address</div>
                    <input
                        className={styles.textboxes}
                        onChange={this.handleChangeEA}
                        name="email"
                        type="email"
                    ></input>
                    <div className={styles.label}>Password</div>
                    <input
                        className={styles.textboxes}
                        onChange={this.handleChangePA}
                        name="password"
                        type="password"
                    ></input>
                    <input
                        className={styles.button}
                        type="submit"
                        value="Login"
                    ></input>
                </form>
            </div>
        );
    }
}

export default Login;
