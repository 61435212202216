import React, { Component } from 'react';
import styles from './saledetailselect.module.css';
import axios from 'axios';

type State = {
    loading: boolean;
    date: string;
    stores: Array<number>;
    activestore: number;
    homeStore: number;
};

type Props = {
    detailChange: (store: number, date: string) => void;
};

class SaleDetailSelect extends Component<Props, State> {
    //in props check for install and if not return the install component instead of the login

    constructor(props: Props) {
        super(props);

        var now: Date = new Date();
        var day: string = ('0' + now.getDate()).slice(-2);
        var month: string = ('0' + (now.getMonth() + 1)).slice(-2);
        var today: string = now.getFullYear() + '-' + month + '-' + day;

        this.state = {
            loading: true,
            date: today,
            stores: [1000],
            activestore: 1000,
            homeStore: 1000,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    componentDidMount() {
        axios
            .get('/api/store/getMyStores')
            .then((response) => {
                this.setState(
                    {
                        loading: false,
                        stores: response.data.stores,
                        activestore: response.data.homeStore,
                        homeStore: response.data.homeStore,
                    },
                    () => {
                        this.props.detailChange(
                            this.state.activestore,
                            this.state.date
                        );
                    }
                );
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({ activestore: Number(event.target.value) }, () => {
            this.props.detailChange(this.state.activestore, this.state.date);
        });
    }

    handleDateChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ date: event.target.value }, () => {
            this.props.detailChange(this.state.activestore, this.state.date);
        });
    }

    render() {
        if (this.state.loading) {
            return (
                <div className={styles.wrapper}>
                    <div className={styles.container}>
                        <div className={styles.subcon}>Loading...</div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={styles.wrapper}>
                    <div className={styles.container}>
                        <div className={styles.subcon}>
                            Date:
                            <input
                                className={styles.input}
                                value={this.state.date}
                                onChange={this.handleDateChange}
                                type="date"
                            ></input>
                        </div>
                        <div className={styles.subcon}>
                            Store:
                            <select
                                className={styles.input}
                                id="stores"
                                onChange={this.handleChange}
                            >
                                {this.state.stores.map((item, index) => (
                                    <option key={index.toString()} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default SaleDetailSelect;
