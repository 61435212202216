import React, { Component } from 'react';
import styles from './menu.module.css';

type State = {};

type Props = {
    updateView: (id: number) => void;
    openMenu: () => void;
    admin: number;
};

class Menu extends Component<Props, State> {
    //in props check for install and if not return the install component instead of the login

    constructor(props: Props) {
        super(props);

        this.state = {};

        this.isAdmin = this.isAdmin.bind(this);
    }

    isAdmin(): boolean {
        if (this.props.admin > 0) return true;
        return false;
    }

    render() {
        return (
            <div className={styles.maincon}>
                <div
                    className={styles.blur}
                    onClick={this.props.openMenu}
                ></div>
                <div className={styles.con}>
                    <div
                        className={styles.itemcon}
                        onClick={() => this.props.updateView(2)}
                    >
                        <div className={styles.home}></div>
                        <div className={styles.name}>Home</div>
                    </div>
                    <div
                        className={styles.itemcon}
                        onClick={() => this.props.updateView(3)}
                    >
                        <div className={styles.reports}></div>
                        <div className={styles.name}>Reports</div>
                    </div>
                    <div
                        className={styles.itemcon}
                        onClick={() => this.props.updateView(4)}
                    >
                        <div className={styles.leads}></div>
                        <div className={styles.name}>Leads</div>
                    </div>
                    <div
                        className={styles.itemcon}
                        onClick={() => this.props.updateView(5)}
                    >
                        <div className={styles.targets}></div>
                        <div className={styles.name}>Targets</div>
                    </div>
                    <div
                        className={styles.itemcon}
                        onClick={() => this.props.updateView(6)}
                    >
                        <div className={styles.history}></div>
                        <div className={styles.name}>History</div>
                    </div>
                    <div
                        className={styles.itemcon}
                        onClick={() => this.props.updateView(7)}
                    >
                        <div className={styles.profile}></div>
                        <div className={styles.name}>My Profile</div>
                    </div>

                    {this.isAdmin() ? (
                        <div
                            className={styles.itemcon}
                            onClick={() => this.props.updateView(8)}
                        >
                            <div className={styles.admin}></div>
                            <div className={styles.name}>Admin</div>
                        </div>
                    ) : null}
                    <div className={styles.footer}>
                        <div className={styles.detail}>Version 0.8.2</div>
                        <div className={styles.detail}>about</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Menu;
