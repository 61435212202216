import React from 'react';
import styles from './loading.module.css';

type Props = {
    desc: string;
};

const Loading = (props: Props) => {
    return (
        <div className={styles.loadingcon}>
            <h1 className={styles.title}>{props.desc}</h1>
            <div className={styles.loader}></div>
        </div>
    );
};

export default Loading;
