import React, { CSSProperties } from 'react';
import styles from './button.module.css';
import { ButtonColor } from '../../types';

type Props = {
    click: () => void;
    color: ButtonColor;
    text: string;
};

const Button = (props: Props) => {
    const s: CSSProperties = {
        backgroundColor: props.color,
    };
    return (
        <div className={styles.con} style={s} onClick={() => props.click()}>
            {props.text}
        </div>
    );
};

export default Button;
