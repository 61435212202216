import React from 'react';
import styles from './titlebox.module.css';

type Props = {
    sum: string;
    desc: string;
};

const TitleBox = ({ sum, desc }: Props) => {
    return (
        <div className={styles.con}>
            <h1 className={styles.summary}>{sum}</h1>
            <h2 className={styles.description}>{desc}</h2>
        </div>
    );
};

export default TitleBox;
