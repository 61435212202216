import React, { Component } from 'react';
import axios from 'axios';
import styles from './leads.module.css';
import LeadItem, { LeadData } from '../leaditem/LeadItem';
import Footer from '../footer/Footer';
import Loading from '../loading/Loading';

type State = {
    leads: Array<LeadData>;
    viewableLeads: Array<LeadData>;
    loading: boolean;
    addinglead: boolean;
    nleadtype: string;
    nleadwait: string;
    nleadlevel: number;
    nleaddesc: string;
    subdivide: number;
    position: number;
};

type Props = {};

class Leads extends Component<Props, State> {
    myRef: any = React.createRef();
    //in props check for install and if not return the install component instead of the login

    constructor(props: Props) {
        super(props);

        this.state = {
            leads: [],
            loading: true,
            addinglead: false,
            nleadtype: 'Sale',
            nleadwait: 'Shipment',
            nleadlevel: 1,
            nleaddesc: '',
            subdivide: 0,
            position: 0,
            viewableLeads: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.leadsNeedToUpdate = this.leadsNeedToUpdate.bind(this);
        this.footerClick = this.footerClick.bind(this);
    }

    componentDidMount() {
        let { offsetHeight } = this.myRef;
        let marginHeight = 150; //header plus some room for filters and stuff
        let sd = Math.floor((offsetHeight - marginHeight) / 105); //how many items can fit
        //lead container is 105px tall
        axios
            .get('/api/leads/getleads')
            .then((response) => {
                this.setState({
                    leads: response.data.leads,
                    loading: false,
                    subdivide: sd, //how many fit
                    viewableLeads: response.data.leads.slice(0, sd), //set the initial viewable items
                });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(function () {});
    }

    handleChange(
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) {
        switch (e.target.name) {
            case 'nleadtype':
                this.setState({ nleadtype: e.target.value });
                break;
            case 'nleadwait':
                this.setState({ nleadwait: e.target.value });
                break;
            case 'nleadlevel':
                this.setState({ nleadlevel: Number(e.target.value) });
                break;
            case 'nleaddesc':
                this.setState({ nleaddesc: e.target.value });
                break;
        }
    }

    leadsNeedToUpdate() {
        axios
            .get('/api/leads/getleads')
            .then((response) => {
                this.setState({
                    leads: response.data.leads,
                    loading: false,
                    viewableLeads: response.data.leads.slice(
                        0,
                        this.state.subdivide
                    ),
                });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(function () {});
    }

    footerClick(id: number) {
        var pos: number = 0;
        if (id === 3) {
            axios
                .post('/api/leads/addlead', {
                    type: this.state.nleadtype,
                    status: this.state.nleadwait,
                    level: this.state.nleadlevel,
                    desc: this.state.nleaddesc,
                })
                .then((response) => {
                    this.leadsNeedToUpdate();
                    this.setState({ addinglead: false, nleaddesc: '' });
                })
                .catch((error) => {});
        } else if (id === 0) {
            this.setState({ addinglead: false, nleaddesc: '' });
        } else if (id === 4) {
            //prev
            pos = this.state.position;
            if (pos !== 0) {
                pos--;
                this.setState({
                    viewableLeads: this.state.leads.slice(
                        pos * this.state.subdivide,
                        pos * this.state.subdivide + this.state.subdivide
                    ),
                    position: pos,
                });
            }
        } else if (id === 5) {
            var limit = Math.ceil(
                this.state.leads.length / this.state.subdivide - 1 // minus 1 because of array index starting at 0
            );
            pos = this.state.position;
            if (pos < limit) {
                pos++;
                this.setState({
                    viewableLeads: this.state.leads.slice(
                        pos * this.state.subdivide,
                        pos * this.state.subdivide + this.state.subdivide
                    ),
                    position: pos,
                });
            }
        } else if (id === 6) {
            this.setState({ addinglead: true });
        }
    }

    render() {
        const leads = this.state.viewableLeads;
        if (this.state.addinglead) {
            return (
                <div className={styles.addLeadContainer}>
                    <h1>New Lead</h1>
                    <form className={styles.formcon}>
                        <p className={styles.seglabel}>Lead Description</p>
                        <textarea
                            className={styles.leaddesc}
                            name="nleaddesc"
                            value={this.state.nleaddesc}
                            onChange={this.handleChange}
                        ></textarea>
                        <p className={styles.seglabel}>
                            No Confidential Information!
                        </p>
                    </form>
                    <form className={styles.formcon}>
                        <p className={styles.seglabel}>This lead is a?</p>
                        <ul className={styles.segmented_control}>
                            <li className={styles.segmented_control_item}>
                                <input
                                    className={styles.segmented_control_input}
                                    type="radio"
                                    value="Sale"
                                    name="nleadtype"
                                    id="option-1"
                                    onChange={this.handleChange}
                                    defaultChecked
                                />
                                <label
                                    className={styles.segmented_control_label}
                                    htmlFor="option-1"
                                >
                                    Sale
                                </label>
                            </li>
                            <li className={styles.segmented_control_item}>
                                <input
                                    className={styles.segmented_control_input}
                                    type="radio"
                                    value="Issue"
                                    name="nleadtype"
                                    id="option-2"
                                    onChange={this.handleChange}
                                />
                                <label
                                    className={styles.segmented_control_label}
                                    htmlFor="option-2"
                                >
                                    Issue
                                </label>
                            </li>
                        </ul>
                    </form>
                    <form className={styles.formcon}>
                        <p className={styles.seglabel}>
                            What are we waiting for?
                        </p>
                        <ul className={styles.segmented_control}>
                            <li className={styles.segmented_control_item}>
                                <input
                                    className={styles.segmented_control_input}
                                    type="radio"
                                    value="Clarification"
                                    name="nleadwait"
                                    id="option-a1"
                                    onChange={this.handleChange}
                                />
                                <label
                                    className={styles.segmented_control_label}
                                    htmlFor="option-a1"
                                >
                                    Clarification
                                </label>
                            </li>
                            <li className={styles.segmented_control_item}>
                                <input
                                    className={styles.segmented_control_input}
                                    type="radio"
                                    value="Shipment"
                                    name="nleadwait"
                                    id="option-a2"
                                    onChange={this.handleChange}
                                    defaultChecked
                                />
                                <label
                                    className={styles.segmented_control_label}
                                    htmlFor="option-a2"
                                >
                                    Shipment
                                </label>
                            </li>
                            <li className={styles.segmented_control_item}>
                                <input
                                    className={styles.segmented_control_input}
                                    type="radio"
                                    value="Customer"
                                    name="nleadwait"
                                    id="option-a3"
                                    onChange={this.handleChange}
                                />
                                <label
                                    className={styles.segmented_control_label}
                                    htmlFor="option-a3"
                                >
                                    Customer
                                </label>
                            </li>
                        </ul>
                    </form>
                    <form className={styles.formcon}>
                        <p className={styles.seglabel}>Priority Level</p>
                        <ul className={styles.segmented_control}>
                            <li className={styles.segmented_control_item}>
                                <input
                                    className={styles.segmented_control_input}
                                    type="radio"
                                    value="1"
                                    name="nleadlevel"
                                    id="option-b1"
                                    onChange={this.handleChange}
                                    defaultChecked
                                />
                                <label
                                    className={styles.segmented_control_label}
                                    htmlFor="option-b1"
                                >
                                    1
                                </label>
                            </li>
                            <li className={styles.segmented_control_item}>
                                <input
                                    className={styles.segmented_control_input}
                                    type="radio"
                                    value="2"
                                    name="nleadlevel"
                                    id="option-b2"
                                    onChange={this.handleChange}
                                />
                                <label
                                    className={styles.segmented_control_label}
                                    htmlFor="option-b2"
                                >
                                    2
                                </label>
                            </li>
                            <li className={styles.segmented_control_item}>
                                <input
                                    className={styles.segmented_control_input}
                                    type="radio"
                                    value="3"
                                    name="nleadlevel"
                                    id="option-b3"
                                    onChange={this.handleChange}
                                />
                                <label
                                    className={styles.segmented_control_label}
                                    htmlFor="option-b3"
                                >
                                    3
                                </label>
                            </li>
                            <li className={styles.segmented_control_item}>
                                <input
                                    className={styles.segmented_control_input}
                                    type="radio"
                                    value="4"
                                    name="nleadlevel"
                                    id="option-b4"
                                    onChange={this.handleChange}
                                />
                                <label
                                    className={styles.segmented_control_label}
                                    htmlFor="option-b4"
                                >
                                    4
                                </label>
                            </li>
                        </ul>
                    </form>
                    <Footer
                        type={1}
                        footerClick={this.footerClick}
                        sticky={false}
                    />
                </div>
            );
        } else if (this.state.loading) {
            return (
                <div
                    className={styles.loadingcon}
                    ref={(el: any) => (this.myRef = el)}
                >
                    <Loading desc={'Loading...'} />
                </div>
            );
        } else {
            return (
                <div className={styles.container}>
                    <div className={styles.titlecon}>Leads</div>
                    <div className={styles.scrollcon}>
                        {leads.map((lead) => (
                            <LeadItem
                                key={lead.id.toString()}
                                lead={lead}
                                leadsNeedToUpdate={this.leadsNeedToUpdate}
                            />
                        ))}
                    </div>
                    <Footer
                        type={4}
                        footerClick={this.footerClick}
                        sticky={true}
                    />
                </div>
            );
        }
    }
}

export default Leads;
