import React, { Component } from 'react';
import axios from 'axios';
import styles from './profile.module.css';

type Props = {
    updateView: (id: number) => void;
};

type State = {
    password: string;
    confirmpassword: string;
    passwordtext: string;
    passwordtexttwo: string;
};

class Profile extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            password: '',
            confirmpassword: '',
            passwordtext: '',
            passwordtexttwo: '',
        };

        this.logout = this.logout.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }

    handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
        switch (event.target.name) {
            case 'password':
                this.setState({ password: event.target.value });
                break;
            case 'confirmpassword':
                this.setState({ confirmpassword: event.target.value });
                break;
        }
    }

    changePassword() {
        if (this.state.password === this.state.confirmpassword) {
            //axios request
            if (this.state.password.length < 5) {
                this.setState({
                    password: '',
                    confirmpassword: '',
                    passwordtext: 'Passwords Not Long Enough',
                });
            } else {
                axios
                    .post('/api/user/changepassword', {
                        password: this.state.password,
                    })
                    .then((response) => {})
                    .catch((error) => {
                        console.log('submit data error.');
                    });
                this.setState({
                    passwordtext: '',
                    passwordtexttwo: 'password changed!',
                    password: '',
                    confirmpassword: '',
                }); //passwords were good so display nothing now
            }
        } else {
            this.setState({
                password: '',
                confirmpassword: '',
                passwordtext: 'Passwords Do Not Match',
                passwordtexttwo: '',
            });
        }
    }

    logout() {
        axios
            .get('/api/user/logout')
            .then((response) => {
                this.props.updateView(1);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    render() {
        const { passwordtext, passwordtexttwo } = this.state;
        return (
            <div className={styles.maincon}>
                <div className={styles.passmaincon}>
                    <div className={styles.titleconn}>
                        Profile - Change Password
                    </div>
                    <div className={styles.invalid}>{passwordtext}</div>
                    <div className={styles.valid}>{passwordtexttwo}</div>
                    <div className={styles.passcon}>
                        <div className={styles.label}>New Password</div>
                        <input
                            className={styles.inputText}
                            name="password"
                            type="password"
                            value={this.state.password}
                            onChange={this.handlePasswordChange}
                        ></input>
                        <div className={styles.label}>Confirm Password</div>
                        <input
                            className={styles.inputText}
                            name="confirmpassword"
                            type="password"
                            value={this.state.confirmpassword}
                            onChange={this.handlePasswordChange}
                        ></input>
                        <div
                            className={styles.passbtn}
                            onClick={this.changePassword}
                        >
                            Submit
                        </div>
                    </div>
                </div>
                <div className={styles.logoutbtn} onClick={this.logout}>
                    Logout
                </div>
            </div>
        );
    }
}

export default Profile;
