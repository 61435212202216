import React, { Component } from 'react';
import styles from './historysubitem.module.css';
import { SaleItem } from '../history/History';

type Props = {
    items: Array<SaleItem>;
};

type State = {
    list: Array<SaleItem>;
};

class HistorySubItem extends Component<Props, State> {
    //in props check for install and if not return the install component instead of the login

    constructor(props: Props) {
        super(props);

        const items = props.items;
        const viewableitems = items.filter((item) => item.display === true);

        this.state = {
            list: viewableitems,
        };
    }

    render() {
        const { list } = this.state;
        return (
            <div className={styles.itemcon}>
                {list.map((item, index) => (
                    <div className={styles.list} key={index}>
                        <div>{item.displayName}</div>
                        <div>
                            {(
                                item.value *
                                item.valuetwo *
                                item.multiplier
                            ).toFixed(2)}
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

export default HistorySubItem;
